<template>
  <div class="smb-client-wrapper">
    <Loader class="fixed" v-if="isLoading" />
    <SmbTitles is-show-title is-show-sub-title title="Settings & Profile" />
    <div class="client-info-container">
      <div class="client-info-container__wrapper">
        <div class="user">
          <div class="user__photo">
            <img
              v-if="getCurrentSmbCustomer.picUrl"
              :src="getCurrentSmbCustomer.picUrl"
              :alt="getCurrentSmbCustomer.name"
            />
            <img
              v-else
              src="@/assets/img/icons/smb-user-avatar.svg"
              :alt="getCurrentSmbCustomer.name"
            />
          </div>
          <div class="user__info">
            <div class="user__name">
              <div class="user__name-val">
                {{ getCurrentSmbCustomer.personal_name }}
              </div>
              <div class="user__name-edit">
                <v-icon
                  @click="$router.push({ name: 'profile-settings-info' })"
                  class="edit-info"
                  >mdi-chevron-right
                </v-icon>
              </div>
            </div>
            <div class="user__contacts">
              <div class="user__contacts-row">
                <i class="icon-email"></i>
                <span>{{ getCurrentSmbCustomer.mail }}</span>
                <v-icon
                  @click="$router.push({ name: 'profile-settings-info' })"
                  class="edit-info"
                  >mdi-chevron-right
                </v-icon>
              </div>
              <div class="user__contacts-row">
                <i class="icon-phone"></i>
                <span v-if="getCurrentSmbCustomer.primary_phone">
                  {{
                    formatPhoneNumber(getCurrentSmbCustomer.primary_phone)
                  }}</span
                >
                <v-icon
                  @click="$router.push({ name: 'profile-settings-info' })"
                  class="edit-info"
                  >mdi-chevron-right
                </v-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="container-item">
          <div class="container-item__title">Business info</div>
          <div class="company-info">
            <div class="row">
              <div class="title">Area</div>
              <div class="data">
                {{ getCurrentSmbCustomer.area }}
              </div>
              <v-icon
                @click="$router.push({ name: 'business-settings-info' })"
                class="edit-info"
                >mdi-chevron-right
              </v-icon>
            </div>
            <div class="row">
              <div class="title">About me</div>
              <div class="data">{{ getCurrentSmbCustomer.about_client }}</div>
              <v-icon
                @click="$router.push({ name: 'business-settings-info' })"
                class="edit-info"
                >mdi-chevron-right
              </v-icon>
            </div>
            <div class="row">
              <div class="title">Website</div>
              <div class="data">
                <a
                  class="client-website-link"
                  :href="getCurrentSmbCustomer.website"
                  target="_blank"
                  >{{ getCurrentSmbCustomer.website }}</a
                >
              </div>
              <v-icon
                @click="$router.push({ name: 'business-settings-info' })"
                class="edit-info"
                >mdi-chevron-right
              </v-icon>
            </div>
            <div class="row">
              <div class="title">Time-Zone</div>
              <div class="data">
                {{ userTimezone }}
              </div>
              <v-icon
                @click="$router.push({ name: 'timezone-settings' })"
                class="edit-info"
                >mdi-chevron-right
              </v-icon>
            </div>
          </div>
        </div>
        <div class="container-item">
          <div class="container-item__title">Social Accounts</div>
          <div class="social">
            <div
              class="social__item"
              v-if="
                getCurrentSmbCustomer.fb_page_link &&
                getCurrentSmbCustomer.fb_page_link.trim() !== ''
              "
            >
              <span class="social__item-actions">
                <span class="edit-btn"> </span>
                <span
                  class="edit-btn"
                  @click="updateSocialDialog.isShow = true"
                >
                  <i class="mdi mdi-refresh"></i>
                </span>
              </span>
              <a
                :href="getCurrentSmbCustomer.fb_page_link"
                class="social__item-link"
                target="_blank"
              >
                <img src="@/assets/img/icons/facebook_f_logo.svg" alt="" />
              </a>
            </div>
            <v-btn
              v-if="
                !getCurrentSmbCustomer.fb_page_link &&
                getCurrentSmbCustomer.saas_redirect_link
              "
              class="social__connect-facebook"
              @click="connectFacebook()"
              >Connect Facebook
            </v-btn>
            <div
              class="social__item"
              v-if="
                getCurrentSmbCustomer.ig_page_link &&
                getCurrentSmbCustomer.ig_page_link.trim() !== ''
              "
            >
              <span class="social__item-actions">
                <span class="edit-btn"> </span>
                <span
                  class="edit-btn"
                  @click="updateSocialDialog.isShow = true"
                >
                  <i class="mdi mdi-refresh"></i>
                </span>
              </span>
              <a
                :href="getCurrentSmbCustomer.ig_page_link"
                class="social__item-link"
                target="_blank"
              >
                <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
              </a>
            </div>
            <v-btn
              class="social__connect-instagram"
              @click="connectInstagram"
              v-if="
                !getCurrentSmbCustomer.ig_page_link &&
                getCurrentSmbCustomer.saas_redirect_link
              "
              >Connect Instagram
            </v-btn>
            <div
              class="social__item"
              v-if="
                getCurrentSmbCustomer.li_page_link &&
                getCurrentSmbCustomer.li_page_link.trim() !== ''
              "
            >
              <a
                :href="getCurrentSmbCustomer.li_page_link"
                class="social__item-link"
                target="_blank"
              >
                <img
                  src="@/assets/img/icons/linkedin_logo_rounded.svg"
                  alt=""
                />
              </a>
            </div>
            <v-btn
              v-if="
                !getCurrentSmbCustomer.li_page_link &&
                getCurrentSmbCustomer.saas_redirect_link_linkedin
              "
              class="social__connect-linkedin"
              @click="connectLinkedin()"
              >Connect Linkedin
            </v-btn>
            <div
              class="social__item"
              v-if="
                getCurrentSmbCustomer.zillow_page &&
                getCurrentSmbCustomer.zillow_page.trim() !== ''
              "
            >
              <span class="social__item-actions">
                <span
                  class="edit-btn"
                  @click="
                    $router.push({
                      name: 'testimonial',
                    })
                  "
                >
                  <i class="icon-edit"></i>
                </span>
                <span class="edit-btn" @click="removeZillowLink">
                  <i class="icon-remove"></i>
                </span>
              </span>
              <a
                :href="getCurrentSmbCustomer.zillow_page"
                class="social__item-link"
                target="_blank"
              >
                <img src="@/assets/img/icons/zillowlink_logo.svg" alt="" />
              </a>
            </div>
            <v-btn
              v-if="!getCurrentSmbCustomer.zillow_page"
              class="social__btn-connect"
              @click="$router.push({ name: 'testimonial' })"
              >Add Zillow page
            </v-btn>
          </div>
          <SmbBranding />
        </div>
        <div class="container-item">
          <div class="container-item__title">Billing info</div>
          <div class="company-info">
            <div class="row">
              <div class="title">Package name</div>
              <div class="data">{{ currentPlan }}</div>
              <v-btn
                v-if="
                  getCurrentSmbCustomer.activity_state.toLowerCase() === 'trial'
                "
                class="upgrade-btn"
                @click="upgradePlanHandler"
                >Upgrade</v-btn
              >
              <div
                class="manage-billing-link"
                v-if="
                  getCurrentSmbCustomer.activity_state.toLowerCase() !== 'trial'
                "
              >
                <a
                  href="https://billing.stripe.com/p/login/fZebM55hMbeT5UcfYY"
                  target="_blank"
                  >manage billing</a
                >
              </div>
            </div>
            <div v-show="false" class="row">
              <div class="title">Remaining credits</div>
              <div class="data">{{ getCurrentSmbCustomer.about_client }}</div>
            </div>
          </div>
        </div>
        <div class="contact-support-block">
          <span class="contact-support-block__title">Contact support:</span>
          <a
            class="contact-support-block__support-email"
            :href="`mailto:support@social-wonder.com?subject=Help%20needed&body=${mailToHref}`"
            >support@social-wonder.com</a
          >
        </div>
      </div>
      <div class="smb-client-wrapper__footer">
        <SmbActionBtn
          text="Back"
          @click="$router.push({ name: 'smb-client' })"
        />
      </div>
    </div>
    <ModalDialog
      :isShow="connectIgDialog.isShow"
      customClass="connect-facebook"
      :size="connectIgDialog.size"
      :footerButtons="connectIgDialog.footerButtons"
      @success="saveChangesConnect"
      @cancel="connectIgDialog.isShow = false"
      @close="connectIgDialog.isShow = false"
    >
      <template v-slot:description>
        <div class="connect-info">
          <div class="connect-info__title">Authenticate with Facebook</div>
          <div class="connect-info__text">
            <h5>Why do I have to authenticate with Facebook?</h5>
            <p class="mb20">
              Facebook requires
              <span class="underline"
                >Instagram business accounts to be connected to a Facebook
                Page</span
              >. For us to be able to post on your behalf, we need to
              authenticate through Facebook.
            </p>
            <h5>Want to post only to Instagram?</h5>
            <p class="mb20">
              No problem, authenticate with Facebook, and select only your
              Instagram account from the Facebook selection menu.
            </p>
            <h5>So what’s next?</h5>
            <p class="mb20">
              Click the button below, sign in to the Facebook profile that has
              Admin permissions for the Facebook Page connected with your
              Instagram account.
            </p>
            <h5>Need help?</h5>
            <p class="mb20">
              Simply send us a message to support@social-wonder.com and we’ll
              schedule a short Zoom (usually takes less than 3 minutes) to help
              you set up everything.
            </p>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="connectFacebookDialog.isShow"
      :isCanClose="false"
      customClass="connect-facebook"
      :size="connectFacebookDialog.size"
      :footerButtons="connectFacebookDialog.footerButtons"
      @success="saveChangesConnect"
      @partnerAccess="givePartnerAccess"
      @cancel="connectFacebookDialog.isShow = false"
      @close="connectFacebookDialog.isShow = false"
    >
      <template v-slot:description>
        <div class="connect-fb">
          <div class="connect-fb__title">Almost done!</div>
          <div class="connect-fb__content">
            <div class="connect-fb__steps">
              <div
                class="connect-fb__step-item"
                :class="{ done: isGiveAccessFb }"
              >
                <span class="icon"></span>
                <span class="text">Give access to schedule Posts</span>
              </div>
              <div class="connect-fb__step-item">
                <span class="icon"></span>
                <span class="text"
                  >Give partner access to schedule Stories</span
                >
              </div>
            </div>
            <div class="connect-fb__info">
              <div class="connect-fb__info-toggle" v-if="!isMoreInfoConnectFb">
                Why do we need partner access ?
                <span @click="isMoreInfoConnectFb = true">Read more</span>
              </div>
              <div class="connect-fb__info-text" v-if="isMoreInfoConnectFb">
                <p>
                  In order to schedule/publish
                  <span class="underline">stories</span> on your behalf on
                  Facebook and/or Instagram, it’s required to get a Partner
                  Access to your account. <br />
                  Simply press the link on top and follow the on screen
                  instructions to give us the access.
                </p>
                <strong class="title">Need help?</strong>
                <p>
                  Send us a message to
                  <a href="mailto:support@social-wonder.com"
                    >support@social-wonder.com</a
                  >
                  or (646) 798-9446 and we’ll schedule a short Zoom (usually
                  takes less than 3 minutes) to help you set up everything.
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="successConnectFacebookDialog.isShow"
      :isCanClose="false"
      customClass="connect-facebook"
      :size="successConnectFacebookDialog.size"
      :footerButtons="successConnectFacebookDialog.footerButtons"
      @close="successConnectFacebookDialog.isShow = false"
    >
      <template v-slot:description>
        <div class="connect-fb">
          <div class="connect-fb__title">Thank you!</div>
          <div class="connect-fb__content thanks-mode">
            <strong>We are now verifying your Partner Access</strong>
            <p>
              This process can take up to 24 hours. <br />
              We will reach out if any further steps are needed. <br />
              In the meantime, you can schedule posts.
            </p>
            <p>
              Had issues & need to give us Partner Access again?
              <span class="link" @click="toLeadsie()">Click here.</span>
            </p>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="updateSocialDialog.isShow"
      :title="updateSocialDialog.title"
      :isCanClose="false"
      @close="updateSocialDialog.isShow = false"
    >
      <template v-slot:footer_slot>
        <div class="modal__footer center_btn">
          <div class="btn-wrapper">
            <button class="btn black-border" @click="updateSocialHandler">
              <template v-if="!isLoadingAcceptBtnForUpdateSocials">
                Yes
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </button>
            <button
              class="btn black"
              @click="updateSocialDialog.isShow = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import H from "@/utils/helper";

import { SMB_TARIFF_PLAN_LIST } from "@/constants/customer";
import { SUPPORTED_TIME_ZONES } from "@/constants/globals";

import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import Loader from "@/components/global/Loader.vue";
import SmbBranding from "@/components/smb-client/SmbBranding.vue";
import ModalDialog from "@/components/global/ModalDialog";

export default {
  name: "Settings",
  components: {
    Loader,
    SmbTitles,
    SmbActionBtn,
    SmbBranding,
    ModalDialog,
  },
  data: () => ({
    isLoading: false,
    tariffPlan: SMB_TARIFF_PLAN_LIST,
    isMoreInfoConnectFb: false,
    connectIgDialog: {
      isShow: false,
      size: 433,
      footerButtons: [
        {
          name: "Cancel",
          emitName: "cancel",
          btnClass: "border",
        },
        {
          name: "Authenticate with Facebook",
          emitName: "success",
          btnClass: "black",
        },
      ],
    },
    connectFacebookDialog: {
      isShow: false,
      size: 433,
      footerButtons: [
        {
          name: "Cancel",
          emitName: "cancel",
          btnClass: "border",
        },
        {
          name: "Give Post Access",
          emitName: "success",
          btnClass: "black",
        },
      ],
    },
    successConnectFacebookDialog: {
      isShow: false,
      size: 433,
      footerButtons: [
        {
          name: "Done",
          emitName: "close",
          btnClass: "black",
        },
      ],
    },
    updateSocialDialog: {
      isShow: false,
      size: 433,
      title: "Are you sure you want to connect a new social media account?",
      footerButtons: [
        {
          name: "Yes",
          emitName: "yes",
          btnClass: "black-border",
        },
        {
          name: "Cancel",
          emitName: "close",
          btnClass: "black",
        },
      ],
    },
    isLoadingAcceptBtnForUpdateSocials: false,
    isGiveAccessFb: false,
    timeZones: SUPPORTED_TIME_ZONES,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    currentPlan() {
      return this.getCurrentSmbCustomer.planName
        ? this.getCurrentSmbCustomer.planName
        : this.getCurrentSmbCustomer && this.getCurrentSmbCustomer.plan
        ? this.tariffPlan.find((i) => i.id === this.getCurrentSmbCustomer.plan)
            .name
        : null;
    },
    mailToHref() {
      const mailBody = `Name: ${
        this.getCurrentSmbCustomer.personal_name || ""
      }\nEmail: ${this.getCurrentSmbCustomer.email || ""}\nPhone: ${
        this.getCurrentSmbCustomer.primary_phone || ""
      }\n`;
      return encodeURI(mailBody);
    },
    userTimezone() {
      const currentTimeZone = this.timeZones.find(
        (i) => i.id === this.getCurrentSmbCustomer.time_zone
      );
      return currentTimeZone ? currentTimeZone.name : "";
    },
  },
  created() {
    this.checkConnectionFbAccount();
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    async removeZillowLink() {
      this.isLoading = true;
      try {
        const params = {
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            zillow_page: "",
          },
        };

        await this.updateCustomer(params);
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    checkConnectionFbAccount() {
      if (
        this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1 &&
        this.$route.query.show_fb_connect_dialog &&
        this.$route.query.show_fb_connect_dialog === "1"
      ) {
        this.connectFacebookDialog.isShow = true;
        this.isGiveAccessFb = true;
        this.connectFacebookDialog.footerButtons[1].name =
          "Give Partner Access";
        this.connectFacebookDialog.footerButtons[1].emitName = "partnerAccess";
      } else if (
        this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1 &&
        this.$route.query.show_fb_connect_dialog &&
        this.$route.query.show_fb_connect_dialog === "0"
      ) {
        this.connectFacebookDialog.isShow = true;
      }
    },
    redirectToConnectSocialsService() {
      if (this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1) {
        window.open(
          this.getCurrentSmbCustomer.saas_redirect_link_list[0],
          "_blank"
        );
      } else {
        window.open(this.getCurrentSmbCustomer.saas_redirect_link, "_blank");
      }
    },
    givePartnerAccess() {
      if (this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1) {
        window.open(
          this.getCurrentSmbCustomer.saas_redirect_link_list[1],
          "_blank"
        );
        setTimeout(() => {
          this.$router.push({ name: "profile-settings" });
          this.connectFacebookDialog.isShow = false;
          this.successConnectFacebookDialog.isShow = true;
        }, 10000);
      }
    },
    toLeadsie() {
      window.open(
        this.getCurrentSmbCustomer.saas_redirect_link_list[1],
        "_blank"
      );
    },
    connectInstagram() {
      this.connectIgDialog.isShow = true;
    },
    connectFacebook() {
      this.isGiveAccessFb = false;
      this.connectFacebookDialog.footerButtons[1].name = "Give Post Access";
      this.connectFacebookDialog.footerButtons[1].emitName = "success";
      if (this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1) {
        this.connectFacebookDialog.isShow = true;
      }

      this.redirectToConnectSocialsService();
    },
    connectLinkedin() {
      window.open(
        this.getCurrentSmbCustomer.saas_redirect_link_linkedin,
        "_blank"
      );
    },
    saveChangesConnect() {
      this.connectIgDialog.isShow = false;
      this.redirectToConnectSocialsService();
    },
    formatPhoneNumber(phoneNumberString) {
      return H.formatPhoneNumber(phoneNumberString);
    },
    upgradePlanHandler() {
      const regex = /@atproperties\b/;
      const redirectLink = `https://social-wonder.com/real-estate-self-service/${
        regex.test(this.getCurrentSmbCustomer.mail)
          ? "onboarding-at-properties"
          : "onboarding-choose-package"
      }/?id=${this.getCurrentSmbCustomer.id}&email=${
        this.getCurrentSmbCustomer.mail
      }&phone=${this.getCurrentSmbCustomer.primary_phone}`;
      window.open(redirectLink, "_blank");
    },
    updateSocialHandler() {
      this.isLoadingAcceptBtnForUpdateSocials = true;
      window.open(
        `https://yvt3sejpo3.execute-api.us-west-1.amazonaws.com/production/customer?task=meta_redirect_url&email=${this.getCurrentSmbCustomer.mail}`,
        "_blank"
      );
      setTimeout(() => (this.isLoadingAcceptBtnForUpdateSocials = false), 3000);
      this.updateSocialDialog.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;

  .buttons-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    flex: 1;
  }

  .support-link {
    margin-bottom: 10px;
  }

  .client-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__wrapper {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 24px;
    }

    .user {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgb(47, 72, 88, 0.1);

      &__photo {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 24px;
        flex-shrink: 0;
        margin-top: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &__name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-edit {
          padding-right: 13px;
        }
      }

      &__contacts {
        width: 100%;
        padding: 8px 12px;
        background: rgba(78, 87, 120, 0.05);
        border: 1px solid rgba(47, 72, 88, 0.05);
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        &-row {
          display: inline-flex;
          align-items: center;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
          }

          i {
            font-size: 12px;
            line-height: 140%;
            margin-right: 10px;
          }
        }
      }
    }

    .social {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &__item {
        margin-right: 12px;
        position: relative;

        &-actions {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 5;

          .edit-btn {
            font-size: 12px;
            cursor: pointer;
            text-decoration: none;
            .mdi-refresh {
              font-size: 15px;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__item-link {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #eeeff0;
        box-sizing: border-box;
        box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06);
        border-radius: 50%;
        position: relative;
        img {
          width: 18px;
          height: 18px;
        }
      }

      &__btn-connect {
        padding: 0 12px;
        text-transform: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #1d1d1b;
        box-shadow: none;
        text-indent: inherit;
        letter-spacing: inherit;
        background: #f5ede2;
        border: 1px solid #1d1d1b;
        border-radius: 16px;
        margin-right: 13px;

        &:last-child {
          margin-right: 0;
        }
      }

      &__connect-facebook {
        background: #3b5998;
        border-radius: 16px;
        font-weight: 700;
        font-size: 10px;
        line-height: 140%;
        color: #fff;
        text-transform: none;
        letter-spacing: inherit;
        border: 1px solid #ffffff;
        margin: 0 2px;
        box-shadow: none;
        padding: 0 9px;
      }

      &__connect-linkedin {
        background: #007ab9;
        border-radius: 16px;
        font-weight: 700;
        font-size: 10px;
        line-height: 140%;
        color: #fff;
        text-transform: none;
        letter-spacing: inherit;
        border: 1px solid #ffffff;
        margin: 0 2px;
        box-shadow: none;
        padding: 0 9px;
      }

      &__connect-instagram {
        background: linear-gradient(
          180deg,
          #5851db 16.67%,
          #e1306c 44.27%,
          #f56040 63.54%,
          #f77737 80.73%,
          #fcaf45 94.79%,
          #ffdc80 100%
        );
        border-radius: 16px;
        font-weight: 700;
        font-size: 10px;
        line-height: 140%;
        color: #fff;
        text-transform: none;
        letter-spacing: inherit;
        border: 1px solid #ffffff;
        margin: 0 2px;
        box-shadow: none;
        padding: 0 9px;
      }
    }

    .logo-container-list {
      display: flex;
    }

    .container-item {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgb(47, 72, 88, 0.1);

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 12px;
      }

      &__small-img {
        width: 100%;
        max-width: 50px;
        margin-right: 10px;

        .logo-title {
          font-weight: 400;
          font-size: 10px;
          margin-bottom: 12px;
        }

        .small-img-container {
          position: relative;
          width: 50px;
          height: 50px;

          &__upload {
            display: flex;
            flex-direction: column;
            align-items: center;

            ::v-deep {
              .v-input__prepend-outer {
                margin-top: 0;
                margin-bottom: 0;

                .icon {
                  width: 40px;
                  height: 50px;
                  flex-shrink: 0;

                  &:after {
                    display: none;
                  }
                }
              }
            }

            &-text {
              font-weight: 500;
              font-size: 10px;
              line-height: 140%;
              text-decoration: underline;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          i {
            cursor: pointer;
            position: absolute;
            background: #fff;
            padding: 2px;
            border-radius: 50%;
            display: inline-flex;
            font-size: 12px;

            &.icon-edit {
              left: 0;
            }

            &.icon-remove {
              right: 0;
            }
          }
        }
      }
    }

    .company-info {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        margin: 0 0 8px 0;
        align-items: center;
        flex-wrap: nowrap;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          font-weight: 500;
          font-size: 12px !important;
          line-height: 140%;
          color: #4e5778;
          width: 90px;
          flex-shrink: 0;
          margin-right: 20px;
        }

        .data {
          font-weight: normal;
          font-size: 14px;
          line-height: 160%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.pricing {
            color: $color-green;
          }

          .client-website-link {
            color: black;
            text-decoration: unset;
          }
        }
      }
      .upgrade-btn {
        padding: 0 12px;
        text-transform: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #1d1d1b;
        box-shadow: none;
        text-indent: inherit;
        letter-spacing: inherit;
        background: #f5ede2;
        border: 1px solid #1d1d1b;
        border-radius: 16px;
        margin-left: 10px;
        width: 120px;
      }
    }

    .edit-info {
      margin-left: auto;
      cursor: pointer;
    }

    .squares-container {
      display: flex;
      flex-direction: row;

      &__item {
        display: inline-flex;
        margin-right: 10px;
        position: relative;

        &-controls {
          padding: 2px 2px 0 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;

          i {
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .square {
        width: 40px;
        height: 40px;

        &__add {
          width: 40px;
          height: 40px;
          border: 1px dashed #000;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 5px;
          font-size: 15px;
        }
      }
    }

    .fonts-container {
      display: flex;
      flex-direction: column;

      &__block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
      }

      .logo-title {
        font-weight: 500;
        font-size: 12px;
      }

      &__item {
        display: inline-flex;
        position: relative;

        &-controls {
          display: flex;
          align-items: center;

          i {
            font-size: 12px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      &__add {
        width: 40px;
        height: 40px;
        border: 1px dashed #000;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        font-size: 15px;
      }

      &__add-font {
        padding: 2px 9px;
        border-radius: 15px;
        background: #000;
        color: #fff;
        cursor: pointer;
        margin-left: 7px;
        font-weight: 500;
        font-size: 12px;
      }

      .font {
        width: 100%;
        font-size: 12px;
        margin-right: 3px;
        margin-left: 7px;
      }
    }
  }

  &__footer {
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
}

.choose-font-pop-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .apply-font {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .select-wrapper {
    .select {
      padding: 2px 21px 2px 10px;
      outline: none;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: right 5px top 50%;
      background-size: 7px auto;
      cursor: pointer;
      border-bottom: 1px solid #908f8f;
    }
  }

  .choose-font-select {
    width: 100%;
  }

  .font-picker {
    .expanded {
      max-height: 170px;
    }
  }
}

.tips {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #1f2325;
    margin-bottom: 10px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 10px 5px;
  }
}

.mb-4 {
  margin-bottom: 20px;
}

.fixed {
  position: fixed;
}

.contact-support-block {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  &__title {
    font-size: 14px;
  }

  &__support-email {
    font-size: 14px;
    padding-left: 10px;
  }
}

.connect-info {
  display: flex;
  flex-direction: column;
  text-align: left;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1f2325;
    text-align: left;
    padding: 14px 20px 11px 38px;
  }

  &__text {
    background: #f5ede2;
    padding: 17px 22px 10px 36px;

    h5 {
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 5px;
      color: #000;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000;
    }

    .mb20 {
      margin-bottom: 20px;
    }

    .underline {
      text-decoration: underline;
    }
  }
}
.connect-fb {
  display: flex;
  flex-direction: column;
  &__title {
    text-align: left;
    padding: 20px 20px 9px 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    color: #1f2325;
  }
  &__content {
    text-align: left;
    background: #f5ede2;
    padding: 20px 20px 0 20px;
    &.thanks-mode {
      color: #000;
      strong {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        margin-bottom: 15px;
        &:first-child {
          font-size: 16px;
        }
        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  &__steps {
    display: flex;
    flex-direction: column;
  }
  &__step-item {
    display: inline-flex;
    align-items: center;
    margin-bottom: 9px;
    &:last-child {
      margin-bottom: 11px;
    }
    .icon {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      background: #fff;
      border-radius: 3px;
      margin-right: 14px;
      border: 1px solid #1d1d1b;
      position: relative;
      &:before {
        content: "";
        width: 2px;
        height: 4px;
        background: #fff;
        position: absolute;
        left: -1px;
        top: 5px;
      }
      &:after {
        font-family: "icomoon" !important;
        content: "\e923";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #70c5b0;
        font-size: 20px;
        display: none;
      }
    }
    .text {
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      color: #000;
    }
    &.done {
      .icon {
        &:after {
          display: block;
        }
      }
      .text {
        text-decoration: line-through;
      }
    }
  }
  &__info {
    color: #000;
    &-toggle {
      font-size: 14px;
      span {
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &__info-text {
    p {
      margin-bottom: 15px;
    }
    .underline {
      text-decoration: underline;
      cursor: pointer;
    }

    a {
      color: #3771c8;
    }
  }
}
.manage-billing-link {
  padding-left: 10px;
  a {
    font-size: 12px;
  }
}
</style>
